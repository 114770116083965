<template>
  <div>
    <!-- HANDELBAY INNOVATIVE -->
    <section id="buyers">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-7 col-md-offset-1 text-left">
            <div class="title" itemscope :itemtype="global.url">
              <h2 class="title-1" itemprop="HandelBay seguridad">¿Querés facilidad, seguridad</h2>

              <h3 class="title-2" itemprop="HandelBay transparancia">y ahorros en las compras?</h3>
              <span class="handelbay-active"></span>
            </div>
            <div class="handelbay-text" itemscope :itemtype="global.url">
              <p itemprop="HandelBay es la solución">
                Markley HandelBay es una plataforma de compras empresariales (marketplace B2B) en la que encontrás más proveedores
                y mejores condiciones comerciales.
              </p>
            </div>
            <br>

            <div class="title-footer">
              <a :href="global.whatsapp" class="btn no-margin-left btn-handelbay-success btn-handelbay-black" target="_blanck" data-target="#modal-demo">Agendá
                tu demo ahora</a>
            </div>
          </div>
          <div class="col-md-5 col-sm-5">
            <div class="computers">
              <img src="../assets/images/landing/handelbay/handelbay_quieres_ahorros.png" alt="Es hora de usar HandelBay en tu día a día">
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <!-- .container -->
    </section>
    <!-- END HANDELBAY INNOVATIVE -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva" class="handelbay-background-curva-buyers">
    </section>
    <!-- .handelbay-background-curva -->
    <!-- Ahorros en las compras -->
    <section id="savings_in_purchases" class="savings_in_purchases">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-5 col-md-offset-1 col-sm-offset-0 col-xs-6 col-xs-offset-3 ">
            <div class="img_man">
              <img src="../assets/images/landing/handelbay/handelbay_man_delivery.png" alt="Es hora de usar HandelBay en tu día a día">
            </div>
          </div>
          <div class="col-md-6 col-sm-7 text-right col-xs-12">
            <div class="handelbay-text" style="padding-bottom:18px;" itemscope :itemtype="global.url">
              <h2 class="title"><b>Ahorros en las compras</b></h2>

              <p class="text" itemprop="HandelBay es la solución">
                ¿Imagínas poder ahorrar hasta un 70% del tiempo operativo asociado
                a tu proceso de compras? Con Markley HandelBay podes darle mejor uso a tu tiempo
                y generar valor a tu área y a tu empresa.
              </p>

              <p class="text">
                Podrás tener ahorros en tus compras empresariales, al acceder a una red en expansión de proveedores
                competitivos.
              </p>

              <h3 class="title space-title"><b>Proveedores verificados</b></h3>

              <p class="text">
                En Markley HandelBay verificamos la documentación de las empresas que se registran. No nos gustan las empresas
                fantasmas que generan inseguridad en los negocios.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End ahorros en las compras -->
    <!-- Red de expansión -->
    <section id="expansion_network">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 col-md-offset-1 text-left">
            <h2 class="title"><b>Red en expansión</b></h2>

            <p class="text" itemprop="HandelBay es la solución">
              ¿Pensaste si el producto que necesitas lo tiene una empresa que aún no conocés? ¿Qué tal si encontrás un
              proveedor en otra zona?
            </p>

            <p class="text">
              La red en expansión de empresas de Markley HandelBay te permite esto y mucho más. Podrás hacer negocios sin fronteras
              comerciales y con más proveedores.
            </p>
          </div>
          <div class="col-md-2 col-sm-4 col-xs-6 col-xs-offset-3 col-sm-offset-0">
            <div class="brand">
              <img src="../assets/images/landing/handelbay/handelbay_img_center.png" alt="Es hora de usar HandelBay en tu día a día">
            </div>
          </div>
          <div class="col-md-4 col-sm-4 text-right col-xs-12">
            <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
              <h3 class="title"><b>Red social y calificaciones</b></h3>

              <p class="text" itemprop="HandelBay red empresarial">
                Markley HandelBay es una red social empresarial. Podrás conocer las otras empresas, su trayectoria, su portafolio,
                documentación actualizadas y mucho más.
              </p>

              <p class="text">
                En Markley HandelBay podrás conocer la calificación y la reputación de las empresa para encontrar el proveedor
                perfecto para tu empresa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Red de expansión -->
    <!-- Compara cotizaciones y proveedores -->
    <section id="compare_quotes" class="text-left">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-7 col-md-offset-1">
            <div class="handelbay-text" itemscope :itemtype="global.url">
              <h2 class="title"><b>Compará cotizaciones y proveedores</b></h2>

              <p class="text" itemprop="HandelBay es la solución">
                ¿Imaginás hacer un cuadro comparativo de las cotizaciones?
              </p>

              <h3 class="title space-title"><b>Encontrá el proveedor adecuado</b></h3>

              <p class="text">
                ¿Te ha pasado de buscar papel de embalar y encontrás resmas de papel para impresoras?
              </p>

              <p class="text">
                Utilizamos UNSPSC® para identificar productos y servicios. Los proveedores marcan productos que ofrecen y vos
                los podrás encontrar de manera acertada y rápida sin importar el idioma o país en que se encuentren.<br>
                <b class="info_title">¡Este es el futuro de las compras!</b>
              </p>
              <h4 class="title space-title"><b>Gestioná tus compras</b></h4>

              <p class="text">En Markley HandelBay recibís requisiciones de las demás áreas de tu empresa; podrás organizarlas,
                agruparlas y publicar procesos de compra que publicás a toda la red de proveedores.
              </p>

              <p class="text space-title">
                Además podés generar reportes, hacer seguimiento postcompra, y tener toda la trazabilidad.
                <br>
                <b class="info_title">¡Podés hacerlo todo en un solo lugar!</b>
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-5 col-xs-6 col-xs-offset-3 col-sm-offset-0">
            <div class="brand">
              <img src="../assets/images/landing/handelbay/handelbay_man2_delivery.png" alt="Es hora de usar HandelBay en tu día a día">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Compara cotizaciones y proveedores -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">
    </section>
    <!-- .handelbay-background-curva -->
    <!-- Listo para innovar -->
    <section id="ready_to_innovate">
      <div class="container">
        <div class="title-footer text-center">
          <h2 class="title">¿Estás listo para innovar en tu área de compras?</h2>
          <router-link to="/tarifas" class="btn btn-handelbay-success" @click.native="scrollUp">
            VER PLANES DE COMPRADOR
          </router-link>
        </div>
      </div>
    </section>
    <!-- End Listo para innovar -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva">
    </section>
    <!-- .handelbay-background-curva -->
    <!-- Optimiza tus compras -->
    <section id="optimize_your_purchases">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
            <div class="title" itemscope itemtype="https://handelbay.com/">
              <h2 class="title-1" itemprop="HandelBay seguridad">Optimizá</h2>

              <h3 class="title-2" itemprop="HandelBay transparancia">tus compras</h3>
            </div>
            <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
              <p itemprop="HandelBay es la solución">
                En cuatro grandes grupos podrás comprender todas las características
                que ofrece HandelBay<br> para las áreas de compras sin importar el tamaño de la empresa.
              </p>
            </div>
            <div class="img_buyers">
              <img src="../assets/images/landing/handelbay/handelbay_optimiza_compras.png" alt="Optimiza tus compras">
            </div>
          </div>
        </div>
        <!-- .row -->
        <div class="title_content text-center">
          <h2 class="title">
            Más proveedores, mejores opciones
            <i class="fa fa-caret-right"></i>
          </h2>
        </div>
        <div class="row">
          <div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
            <div class="title" itemscope itemtype="https://handelbay.com/">
              <h2 class="title-1" itemprop="HandelBay seguridad">Funcionalidades para</h2>

              <h3 class="title-2" itemprop="HandelBay transparancia">compradores</h3>
            </div>
            <Functionalities />
            <div class="title-footer text-center">
              <h4 class="title"><b><em>¡Quiero ser parte de HandelBay!</em></b></h4>
              <a :href="global.sign_up" class="btn btn-handelbay-success btn-handelbay-black" style="margin-top:15px !important;">
                REGISTRATE GRATIS AHORA
              </a>
              <!--
              <h4 class="subtitle">
                <a class="subtitle" data-toggle="modal" data-target="#compare-planes">
                  Conoce aquí los detalles de los planes para comprador
                </a>
              </h4>
              -->
            </div>
          </div>
        </div>
      </div>
      <!-- .row -->
    <!-- .container -->
    </section>
    <!-- End Optimiza tus compras -->
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">
    </section>
    <!-- .handelbay-background-curva -->
    
    <PorqueHandelbay />
    <ModalTuDemo />
    <ModalPlans />
  </div>
</template>
<script>
import global from "@/components/Global.vue"
import Functionalities from "@/components/FunctionalitiesBuyer.vue"
import ModalPlans from "@/components/ModalPlans.vue"
import PorqueHandelbay from "@/components/PorqueHandelbay.vue"
import ModalTuDemo from "@/components/ModalTuDemo.vue"

export default {
  components:{
    Functionalities,
    ModalPlans,
    PorqueHandelbay,
    ModalTuDemo
  },
  data(){
    return{
      global: global
    }
  },
  methods:{
    scrollUp: () =>{
      window.scroll({top:0})
    }
  }
}
</script>